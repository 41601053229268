<template>
  <!-- 公告列表 -->
  <div id="app">
    <div class="cart-con">
      <div class="cart-ban">
        <img src="@/assets/img/notice-ban.jpg" />
      </div>
      <div class="cson-container notice-con">
        <div class="title">公告列表</div>
        <div class="notice-list">
          <div v-for="(item, index) in noticelist" :key="index" class="slist">
            <h2><span v-if="item.isLatest">最新</span>{{ item.title }}</h2>
            <p :class="item.isopen ? 'open' : ''">{{ item.words }}</p>
            <div class="date">
              <img src="@/assets/center/date-icon.png" />{{ item.date }}
            </div>
            <button @click="open(index)">
              {{ item.isopen ? "收起" : "展开"
              }}<img
                :class="item.isopen ? 'close' : ''"
                src="@/assets/img/open-icon.png"
              />
            </button>
          </div>
          <el-pagination
            :current-page="currentPage4"
            :page-size="100"
            :page-sizes="[100, 200, 300, 400]"
            :total="400"
            class="notice-paginationn"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      noticelist: [
        {
          title:
            "题公告标题公告标题公告标题公告标题公告标题公告标题公告公告标题公告标题",
          words:
            "Leetcode601-900 题目视频讲解上线讲解上讲Leetcode601-900 题目视频讲解上线讲解上讲.Leetcode601-900 题目视频讲解上线讲解上讲.\n" +
            "Leetcode601-900 题目.....",
          date: "2020/02/18",
          isLatest: true,
          isopen: false,
        },
        {
          title:
            "题公告标题公告标题公告标题公告标题公告标题公告标题公告公告标题公告标题",
          words:
            "Leetcode601-900 题目视频讲解上线讲解上讲Leetcode601-900 题目视频讲解上线讲解上讲.Leetcode601-900 题目视频讲解上线讲解上讲.\n" +
            "Leetcode601-900 题目.....",
          date: "2020/02/18",
          isLatest: true,
          isopen: false,
        },
        {
          title:
            "题公告标题公告标题公告标题公告标题公告标题公告标题公告公告标题公告标题",
          words:
            "Leetcode601-900 题目视频讲解上线讲解上讲Leetcode601-900 题目视频讲解上线讲解上讲.Leetcode601-900 题目视频讲解上线讲解上讲.\n" +
            "Leetcode601-900 题目.....",
          date: "2020/02/18",
          isLatest: true,
          isopen: false,
        },
        {
          title:
            "题公告标题公告标题公告标题公告标题公告标题公告标题公告公告标题公告标题",
          words:
            "Leetcode601-900 题目视频讲解上线讲解上讲Leetcode601-900 题目视频讲解上线讲解上讲.Leetcode601-900 题目视频讲解上线讲解上讲.\n" +
            "Leetcode601-900 题目.....",
          date: "2020/02/18",
          isLatest: true,
          isopen: false,
        },
        {
          title:
            "题公告标题公告标题公告标题公告标题公告标题公告标题公告公告标题公告标题",
          words:
            "Leetcode601-900 题目视频讲解上线讲解上讲Leetcode601-900 题目视频讲解上线讲解上讲.Leetcode601-900 题目视频讲解上线讲解上讲.\n" +
            "Leetcode601-900 题目.....",
          date: "2020/02/18",
          isLatest: true,
          isopen: false,
        },
        {
          title:
            "题公告标题公告标题公告标题公告标题公告标题公告标题公告公告标题公告标题",
          words:
            "Leetcode601-900 题目视频讲解上线讲解上讲Leetcode601-900 题目视频讲解上线讲解上讲.Leetcode601-900 题目视频讲解上线讲解上讲.\n" +
            "Leetcode601-900 题目.....",
          date: "2020/02/18",
          isLatest: true,
          isopen: false,
        },
      ],
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    open(index) {
      this.noticelist[index].isopen = !this.noticelist[index].isopen;
    },
  },
};
</script>

<style>
</style>
